import React, { FC, useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { getTrainingIndicatorDataRef, useAuth0Token } from "../common/http-requests";
import { trainingIndicatorDataRefRequestType, trainingIndicatorDataRefResponseType } from "../common/type";
import { Store } from "../store";

const BAR_CHART_SIZE: number[] = [
  1900, 320
];

const BAR_CHART_COLORS: string[] = [
  "#92DDFE","#529DDE","#226DAE","#023D7E","#a7a7a7",
];

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
  tableHeadStyle : {
    textAlign: "center",
    borderRight: "solid 1px #fff",
    lineHeight: "1rem",
    padding: "10px 2px",
  },
  tableCellStyle: {
    position: 'relative',
    padding: "0 2px",
    textAlign: 'center',
    overflow: "hidden",
  },
  tableCellQualsStyle: {
    border: 'solid 1px #e2e2e2',
    boxSizing: 'border-box',
  },
  tableCellPsStyle: {
    border: 'solid 1px #bbcddd',
    boxSizing: 'border-box',
  },
  tableCellVertical: {
    writingMode: 'vertical-rl', 
    textOrientation: 'upright',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    width: '100%',
    height: '15%',
  },
  tableBodyLabelStyle: {
    textAlign: 'left',
    color: `${BAR_CHART_COLORS[1]}`,
    fontSize: '.8rem',
    padding: '2px',
  },
  tableBodyValueStyle: {
    textAlign: 'center',
    color: `${BAR_CHART_COLORS[0]}`,
    fontSize: '.8rem',
    padding: '2px',
  },
  tableCellRefStyle: {
    border: 'solid 1px #e2e2e2',
    boxSizing: 'border-box',
  },
  accordionDetailsStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // 水平方向の中央揃え
    justifyContent: "center", // 垂直方向の中央揃え
    minHeight: "200px", // 必要に応じて高さを設定
    width: `${BAR_CHART_SIZE[0]}px`, // 修正: 幅を親要素いっぱいに設定
    textAlign: "center", // 修正: テキストを中央揃え
    margin: "0 auto", // 修正: 要素を水平中央に配置
  },
});

const AccordionLabel = styled(Box)(() => ({
  position: "relative",
  width: '100%', 
  height: 'auto', 
  maxWidth: `${BAR_CHART_SIZE[0]}px`, 
  margin: '8px auto 0',
  borderTop: `solid 1px ${BAR_CHART_COLORS[2]}`,
  borderBottom: `solid 1px ${BAR_CHART_COLORS[2]}`,
  color: `${BAR_CHART_COLORS[2]}`,
}));

const earnedSemesterCodeFormat = (props: string) => {
  const semester = props === "1" ? "前期" : props === "2" ? "後期" : "-";
  return semester;
};

const DefaultCreditDataRef: trainingIndicatorDataRefResponseType = {
  data_ref: [],
};

const mextHumanDevelopmentIndicatorList = [
  { id: "01", label: "教職理解等の学び続ける姿勢" },
  { id: "02", label: "良好な人間関係" },
  { id: "03", label: "学校組織マメジメント" },
  { id: "04", label: "連携協働を通じて課題を解決しようとする姿勢" },
  { id: "05", label: "危機管理の知識や視点" },
  { id: "06", label: "学習者中心の授業を創造" },
  { id: "07", label: "カリキュラム・マメジメント" },
  { id: "08", label: "授業設計・実践・評価・改善" },
  { id: "09", label: "各教科等の専門的知識" },
  { id: "10", label: "良さや可能性を伸ばす姿勢" },
  { id: "11", label: "個に応じて指導や集団指導" },
  { id: "12", label: "学校生活への適応や人格の成長への援助" },
  { id: "13", label: "自分らしい生き方を実現するための力を育成" },
  { id: "14", label: "可能性や活躍の場を引き出す集団づくり" },
  { id: "15", label: "学習上・生活上の支援の工夫" },
  { id: "16", label: "情報活用能力を育成するための授業実線" },
  { id: "17", label: "教育データを適切に活用" },
];
const mhdi = mextHumanDevelopmentIndicatorList;

const tokushimaHumanDevelopmentIndicatorList = [
  { id: "01", label: "使命感・倫理観" },
  { id: "02", label: "識見・学び続ける力" },
  { id: "03", label: "社会性・コミュニケーション力" },
  { id: "04", label: "学校組織マネジメント力" },
  { id: "05", label: "連携・協働力" },
  { id: "06", label: "危機管理力" },
  { id: "07", label: "カリキュラム・マネジメント力" },
  { id: "08", label: "授業構想力" },
  { id: "09", label: "授業実践力" },
  { id: "10", label: "授業省察力・改善力" },
  { id: "11", label: "児童生徒理解・指導力" },
  { id: "12", label: "集団づくり力" },
  { id: "13", label: "課題解決力" },
  { id: "14", label: "未来ビジョン育成力" },
  { id: "15", label: "個に応じた指導・支援力" },
  { id: "16", label: "チームによる実践" },
  { id: "17", label: "｢わかった｣｢できた｣を育 む（多様性に基づく）学習支援力" },
  { id: "18", label: "｢わかった｣｢できた｣を育む（多様性に基づく）生活支援力" },
  { id: "19", label: "ＩＣＴを学習指導に利活用する力" },
  { id: "20", label: "ＩＣＴを効率的な業務の遂行に活用する力" },

];
const thdi = tokushimaHumanDevelopmentIndicatorList;

const tableHeadListCS = [
  { label: "修得年", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
  { label: "修得期", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
  { label: "授業科目", width: "20vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
  { label: "単位", width: "3vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
  { label: "得点", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
]

const tableHeadListPers = [
  { label: "区分", width: "3vw", color: "white", backgroundColor: BAR_CHART_COLORS[1] },
  { label: "8領域", width: "7vw", color: "white", backgroundColor: BAR_CHART_COLORS[1] },
  { label: "26観点", width: "20vw", color: "white", backgroundColor: BAR_CHART_COLORS[1] },
];

const mextTableHeadListQuals = [
  { label: "教職に必要な素養", width: "4vw", color: "white", colSpan: 5, backgroundColor: BAR_CHART_COLORS[0], indicators: [mhdi[0], mhdi[1], mhdi[2], mhdi[3], mhdi[4]] },
  { label: "学習指導", width: "4vw", color: "white", colSpan: 4, backgroundColor: BAR_CHART_COLORS[0], indicators: [mhdi[5], mhdi[6], mhdi[7], mhdi[8]] },
  { label: "生徒指導", width: "4vw", color: "white", colSpan: 5, backgroundColor: BAR_CHART_COLORS[0], indicators: [mhdi[9], mhdi[10], mhdi[11], mhdi[12], mhdi[13]] },
  { label: "特別は配慮や支援を必要とする子供への対応", width: "4vw", color: "white", colSpan: 1, backgroundColor: BAR_CHART_COLORS[0], indicators: [mhdi[14]] },
  { label: "ICTや情報・教育データの利活用", width: "4vw", color: "white", colSpan: 2, backgroundColor: BAR_CHART_COLORS[0], indicators: [mhdi[15], mhdi[16]] },
];

const tokushimaTableHeadListQuals = [
  { label: "素養", width: "4vw", color: "white", colSpan: 6, backgroundColor: BAR_CHART_COLORS[0], indicators: [thdi[0], thdi[1], thdi[2], thdi[3], thdi[4], thdi[5]] },
  { label: "授業力・学習指導", width: "4vw", color: "white", colSpan: 4, backgroundColor: BAR_CHART_COLORS[0], indicators: [thdi[6], thdi[7], thdi[8], thdi[9]] },
  { label: "担任力・生徒指導", width: "4vw", color: "white", colSpan: 4, backgroundColor: BAR_CHART_COLORS[0], indicators: [thdi[10], thdi[11], thdi[12], thdi[13]] },
  { label: "特別な配慮・支援", width: "4vw", color: "white", colSpan: 4, backgroundColor: BAR_CHART_COLORS[0], indicators: [thdi[14], thdi[15], thdi[16], thdi[17]] },
  { label: "ICTの利活用", width: "4vw", color: "white", colSpan: 2, backgroundColor: BAR_CHART_COLORS[0], indicators: [thdi[18], thdi[19]] },
];


const TrainingIndicatorDataRefPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();
  const [tiMextData, setTIMextData] = useState<trainingIndicatorDataRefResponseType>(DefaultCreditDataRef);
  const [tiTokushimaData, setTITokushimaData] = useState<trainingIndicatorDataRefResponseType>(DefaultCreditDataRef);
  const [expandedNationwide, setExpandedNationwide] = useState(true);
  const [expandedTokushima, setExpandedTokushima] = useState(true);
  const toggleNationwide = () => setExpandedNationwide(!expandedNationwide);
  const toggleTokushima = () => setExpandedTokushima(!expandedTokushima);

  // const createData = (data: trainingIndicatorDataRefResponseType, index:number) => {
  //   const reData = data.data_ref;
  //   const fixData = [
  //     { group: "1.教師としての構え",
  //       span: 3,
  //       data: [
  //         {value: reData[0], label: "01倫理観・使命感・人権意識" },
  //         {value: reData[1], label: "02子供観" },
  //         {value: reData[2], label: "03学習観" },
  //       ]},
  //     { group: "2.教師として必要な専門的知識",
  //       span: 3,
  //       data: [
  //         {value: reData[3], label: "04教職の専門的知識" },
  //         {value: reData[4], label: "05現代社会の諸課題に関する知識"},
  //         {value: reData[5], label: "06領域・教科の専門的知識"},
  //       ]},
  //     { group: "3.教師として必要な基本的技能",
  //       span: 4,
  //       data: [
  //         {value: reData[6], label: "07教育データの利活用能力"},
  //         {value: reData[7], label: "08個人的指導力（ファシリテート力）"},
  //         {value: reData[8], label: "09子供理解力"},
  //         {value: reData[9], label: "10集団指導力（学級経営力）"},
  //       ]},
  //     { group: "4.教師として必要な実践的指導力",
  //       span: 2,
  //       data: [
  //         {value: reData[10], label: "11学習指導及び生徒指導における構想力・展開力・評価力"},
  //         {value: reData[11], label: "12特別な配慮や支援を必要とする子供への対応力"},
  //       ]},
  //     { group: "5.人間性",
  //       span: 4,
  //       data: [
  //         {value: reData[12], label: "13ダイバーシティ・インクルージョン"},
  //         {value: reData[13], label: "14レジリエンス"},
  //         {value: reData[14], label: "15自己肯定感"},
  //         {value: reData[15], label: "16主体性・自律性"},
  //       ]},
  //     { group: "6.連携・協働力",
  //       span: 3,
  //       data: [
  //         {value: reData[16], label: "17コミュニケーション力"},
  //         {value: reData[17], label: "18チームワーキング力"},
  //         {value: reData[18], label: "19合意形成力"},
  //       ]},
  //     { group: "7.課題発見・価値創造力",
  //       span: 5,
  //       data: [
  //         {value: reData[19], label: "20ICT活用力"},
  //         {value: reData[20], label: "21データの分析・活用力"},
  //         {value: reData[21], label: "22価値創造力"},
  //         {value: reData[22], label: "23見出した課題に対する多面的・多角的な見方・考え方"},
  //         {value: reData[23], label: "24論理的な思考力・表現力"},
  //       ]},
  //     { group: "8.省察力と職能成長を志向する態度",
  //       span: 2,
  //       data: [
  //         {value: reData[24], label: "25学び続ける態度"},
  //         {value: reData[25], label: "26自己調整力"},
  //       ]},
  //   ];

  //   if (index === 1) {
  //     // console.log("★mextFixData = ", fixData);
  //     setTIMextData(fixData);
  //   } else if (index === 2) {
  //     // console.log("★tokushimaFixData = ", fixData);
  //     setTITokushimaData(fixData);
  //   }

  // };

  // データ例
  //   "data_ref": [
  //     { 'earned_period': 2019, 'earned_semester_code': '1', 'subject_name': '日本国憲法', 'credit_score': 100, 'ps_credits': [1.0, ..., 0.0] },
  //     ...
  //     ]
  // const [trainingIndicatorDataRefData, setTrainingIndicatorDataRefData] = useState<trainingIndicatorDataRefResponseType>(DefaultCreditDataRef);

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getTrainingIndicatorData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getTrainingIndicatorData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      // 全国指標のデータを取得
      const requestMextParams: trainingIndicatorDataRefRequestType = {
        login_user_id,
        target_type: "mext",
      };
      const mextData: trainingIndicatorDataRefResponseType = await getTrainingIndicatorDataRef(
        await getToken(),
        requestMextParams
      );
      // 徳島県指標のデータを取得
      const requestTokushimaParams: trainingIndicatorDataRefRequestType = {
        login_user_id,
        target_type: "tokushima",
      };
      const tokushimaData: trainingIndicatorDataRefResponseType = await getTrainingIndicatorDataRef(
        await getToken(),
        requestTokushimaParams
      );
      
      // 文科省指標データをset
      setTIMextData(mextData);
      // 徳島県指標データをset
      setTITokushimaData(tokushimaData);
      // setTrainingIndicatorDataRefData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };
  
  interface TableCellProps {
    group?: string;
    span?: number;
    data: { label: string; value: number; orverValue: number }[];
    index?: number;
  };
  // tableのrowspanの都合上、各項目の最初の１行目はTITableFirstRowを使用
  const TITableFirstRow:React.FC<TableCellProps> = ({group, span, data, index }) => {
    return (
      <TableRow key={group}>
        {index === 0 && (
          <TableCell 
            className={`${classes.tableBodyLabelStyle} ${classes.tableCellPsStyle}`}
            rowSpan={12}
          >1.教師としてのコンピテンシー</TableCell>
        )}
        {index === 4 && (
          <TableCell 
            className={`${classes.tableBodyLabelStyle} ${classes.tableCellPsStyle}`}
            rowSpan={14}
          >2.社会で活躍できる汎用的なスキル</TableCell>
        )}
        <TableCell 
          className={`${classes.tableBodyLabelStyle} ${classes.tableCellPsStyle}`}
          rowSpan={span}
        >{group}</TableCell>
        <TableCell 
          className={`${classes.tableBodyLabelStyle} ${classes.tableCellPsStyle}`}
        >{data[0].label}</TableCell>
        <TableCell sx={{ width: '2px', padding: '0' }}/>
        {data?.map((rowData:any, rowIndex:number) => (
          rowIndex === 0 && (
            rowData.value.training_credits?.map(( rowValue: any, rowValueIndex: number ) => (
              <TableCell 
                key={`${rowData.label}-${rowValue}-${rowValueIndex}`}
                className={`${classes.tableBodyValueStyle} ${classes.tableCellQualsStyle}`} 
              >{typeof rowValue === 'number' ? rowValue.toFixed(3) : rowValue}</TableCell>
            ))
          )
        ))}
      </TableRow>
    );
  };
  // tableのrowspanの都合上、各項目の２行目からはTITableLeftRowsを使用
  const TITableLeftRows:React.FC<TableCellProps> = ({ data }) => {
    return (
      <>
      {data?.map((rowData:any, rowIndex:number) =>(
        rowIndex > 0 && ( // data[0]は重複するのでskip
        <TableRow key={`${rowData.label}-${rowIndex}`}>
          <TableCell 
            className={`${classes.tableBodyLabelStyle} ${classes.tableCellPsStyle}`}
          >{data[rowIndex].label}</TableCell>
          <TableCell sx={{ width: '2px', padding: '0' }}/>
          {rowData.value.training_credits?.map(( rowValue: any, rowValueIndex: number ) => (
            // 小数点第3位まで表示
            <TableCell 
              key={`${rowData.label}-${rowValue}-${rowValueIndex}`}
              className={`${classes.tableBodyValueStyle} ${classes.tableCellQualsStyle}`} 
            >{typeof rowValue === 'number' ? rowValue.toFixed(3) : rowValue}</TableCell>
          ))}
        </TableRow>
      )))}
      </>
    );
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {feedbackInfo.open &&
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Paper className={classes.chartContainer}>
              <AccordionLabel>
                <AccordionSummary
                  onClick={toggleNationwide}
                  expandIcon={expandedNationwide ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  <Typography>教員資質指標（文部科学省）</Typography>
                </AccordionSummary>
              </AccordionLabel>
              {expandedNationwide && (
                <AccordionDetails>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead key="table-head">
                        <TableRow>
                          <TableCell 
                            className={classes.tableHeadStyle} 
                            sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[4] }}
                            colSpan={5}
                          >授業科目</TableCell>
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          <TableCell 
                            className={classes.tableHeadStyle} 
                            sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[0] }}
                            colSpan={17}
                          >公立の小学校等の校長及び教員としての資質の向上に関する指標の策定に関する指針
                          <br />に基づく教師に共通的に求められる資質の具体的内容
                          <br />（令和4年8月31日改正）</TableCell>
                        </TableRow>
                        <TableRow>
                          {tableHeadListCS?.map((item: any, index: number) => (
                            <TableCell 
                              key={`${item.label}-${index}`}
                              className={classes.tableHeadStyle} 
                              sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                              rowSpan={2}
                            >{item.label}
                            </TableCell>
                          ))}
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          {mextTableHeadListQuals?.map((item: any, index: number) => (
                            <TableCell 
                              key={`${item.label}-${index}`}
                              className={classes.tableHeadStyle} 
                              sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                              colSpan={item.colSpan}
                            >{item.label}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          {mextTableHeadListQuals?.map((quals: any, qualsindex: number) => (
                            quals.indicators?.map((item: any, index: number) =>(
                              <TableCell 
                                key={`${item.label}-${index}`}
                                className={classes.tableHeadStyle} 
                                sx={{ width: '1vw', color: quals.color, backgroundColor: quals.backgroundColor, fontSize: '.8rem'  }}
                              ><div className={classes.tableCellVertical}>{item.label}</div>
                              </TableCell>
                            ))
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="table-body">
                        {tiMextData.data_ref?.map((item: any, index: number) =>(
                          <TableRow key={`table-body-row-${index}`}>
                            <React.Fragment key={`${item.subject_name}-${index}`}>
                              <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.earned_period}</TableCell>
                              <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{earnedSemesterCodeFormat(item.earned_semester_code)}</TableCell>
                              <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.subject_name}</TableCell>
                              <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit}</TableCell>
                              <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit_score}</TableCell>
                              <TableCell sx={{ width: '2px', padding: '0' }}/>
                              {item.training_credits?.map((ps: any, psindex: number) => (
                                <TableCell 
                                  key={`ps-${psindex}`}
                                  className={`${classes.tableCellStyle} ${classes.tableCellPsStyle}`} 
                                  sx={{ color: BAR_CHART_COLORS[3] }}
                                  >{(typeof ps === 'number' && ps !== 0) ? ps.toFixed(3) : ps}</TableCell>
                              ))}
                            </React.Fragment>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              )}
              <AccordionLabel>
                <AccordionSummary
                  onClick={toggleTokushima}
                  expandIcon={expandedTokushima ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  <Typography>とくしま教員育成指標</Typography>
                </AccordionSummary>
              </AccordionLabel>
              {expandedTokushima && (
                <AccordionDetails>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead key="table-head">
                        <TableRow>
                          <TableCell 
                            className={classes.tableHeadStyle} 
                            sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[4] }}
                            colSpan={5}
                          >授業科目</TableCell>
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          <TableCell 
                            className={classes.tableHeadStyle} 
                            sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[0] }}
                            colSpan={20}
                          >「とくしま教員育成指標」求められる資質・能力について
                          <br />小学校教諭，中学校・中等教育学校(前期)教諭，高等学校・中等教育学校(後期)教諭
                          <br />（令和5年2月改正）</TableCell>
                        </TableRow>
                        <TableRow>
                          {tableHeadListCS?.map((item: any, index: number) => (
                            <TableCell 
                              key={`${item.label}-${index}`}
                              className={classes.tableHeadStyle} 
                              sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                              rowSpan={2}
                            >{item.label}
                            </TableCell>
                          ))}
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          {tokushimaTableHeadListQuals?.map((item: any, index: number) => (
                            <TableCell 
                              key={`${item.label}-${index}`}
                              className={classes.tableHeadStyle} 
                              sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                              colSpan={item.colSpan}
                            >{item.label}
                            </TableCell>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          {tokushimaTableHeadListQuals?.map((quals: any, qualsindex: number) => (
                            quals.indicators?.map((item: any, index: number) =>(
                              <TableCell 
                                key={`${item.label}-${index}`}
                                className={classes.tableHeadStyle} 
                                sx={{ width: '1vw', color: quals.color, backgroundColor: quals.backgroundColor, fontSize: '.8rem'  }}
                              ><div className={classes.tableCellVertical}>{item.label}</div>
                              </TableCell>
                            ))
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody key="table-body">
                        {tiTokushimaData.data_ref?.map((item: any, index: number) =>(
                          <TableRow key={`table-body-row-${index}`}>
                          <React.Fragment key={`${item.subject_name}-${index}`}>
                            <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.earned_period}</TableCell>
                            <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{earnedSemesterCodeFormat(item.earned_semester_code)}</TableCell>
                            <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.subject_name}</TableCell>
                            <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit}</TableCell>
                            <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit_score}</TableCell>
                            <TableCell sx={{ width: '2px', padding: '0' }}/>
                            {item.training_credits?.map((ps: any, psindex: number) => (
                              <TableCell 
                                key={`ps-${psindex}`}
                                className={`${classes.tableCellStyle} ${classes.tableCellPsStyle}`} 
                                sx={{ color: BAR_CHART_COLORS[3] }}
                                >{(typeof ps === 'number' && ps !== 0) ? ps.toFixed(3) : ps}</TableCell>
                            ))}
                          </React.Fragment>
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              )}
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default TrainingIndicatorDataRefPage;
