import { useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  loggingResponseType,
  loggingRequestType,
  userResponseType,
  standardTagsResponseType,
  userInfoResponseType,
  creditResponseType,
  creditDataRefResponseType,
  chatbotLLMMessageRequestType,
  chatbotMessagesResponseType,
  trainingIndicatorResponseType,
  trainingIndicatorDataRefResponseType,
  gpaResponseType,
  gpaDataRefResponseType,
  portfolioResponseType,
  NCBTResponseType,
  NCBTDataRefResponseType,
  SETSResponseType,
  SETSDataRefResponseType,
} from "./type";

let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:8000",
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

export function useAuth0Token() {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const getToken = useCallback(async () => {
    if (process.env.REACT_APP_AUTH0_DOMAIN) {
      await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      return idToken?.__raw;
    } else {
      return undefined;
    }
  }, [getAccessTokenSilently, getIdTokenClaims]);
  return { getToken };
}

export const sendLog = async (
  idToken: string | undefined,
  params: any /*loggingRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<loggingResponseType>(
      "/v1/logging",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    throw e;
  }
};

const _sendLogException = async (
  idToken: string | undefined,
  functionCallParams: any
) => {
  const logText = JSON.stringify(functionCallParams);
  const requestParams: loggingRequestType = {
    log_text: logText,
  };
  await sendLog(idToken, requestParams);
};

export const loginUser = async (
  idToken: string | undefined,
  params: any /*loginUserRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post(
      "/v2/login_user",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const getUser = async (idToken: string | undefined, params: any) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<userResponseType>(
      "/v1/user",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const getStandardTags = async (idToken: string | undefined) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.get<standardTagsResponseType>(
      "/v1/standard_tags",
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    const params = "";
    _sendLogException(idToken, params);
    throw e;
  }
};

// 学籍情報画面向け
export const getUserInfo = async (
  idToken: string | undefined,
  params: any /*userInfoRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<userInfoResponseType>(
      "/v2/user_info",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// 特記業績画面向け
export const getUserAchievements = async (
  idToken: string | undefined,
  params: any /*userAchievementsRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post(
      "/v2/user_achievements",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const updateUserAchievements = async (
  idToken: string | undefined,
  params: any /*updateUserAchievementsRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post(
      "/v2/update_user_achievements",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// ヘルプ画面向け
export const getHelp = async (
  idToken: string | undefined,
  params: any /*helpRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post(
      "/v2/help",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// 単位画面向け
export const getCredit = async (
  idToken: string | undefined,
  params: any /*creditRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<creditResponseType>(
      "/v2/credit",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// 単位画面(データ参照)向け
export const getCreditDataRef = async (
  idToken: string | undefined,
  params: any /*creditRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<creditDataRefResponseType>(
      "/v2/credit_data_ref",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// 育成指標画面向け
export const getTrainingIndicator = async (
  idToken: string | undefined,
  params: any /*trainingIndicatorRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<trainingIndicatorResponseType>(
      "/v2/training_indicator",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// 育成指標画面(データ参照)向け
export const getTrainingIndicatorDataRef = async (
  idToken: string | undefined,
  params: any /*trainingIndicatorRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<trainingIndicatorDataRefResponseType>(
      "/v2/training_indicator_data_ref",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// portfolio画面向け
export const getPortfolio = async (
  idToken: string | undefined,
  params: any /*portfolioRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<portfolioResponseType>(
      "/v2/portfolio",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// GPA画面向け
export const getGpaScore = async (
  idToken: string | undefined,
  params: any /*gpaRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<gpaResponseType>(
      "/v2/gpa",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// GPA画面(データ参照)向け
export const getGpaScoreDataRef = async (
  idToken: string | undefined,
  params: any /*gpaRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<gpaDataRefResponseType>(
      "/v2/gpa_data_ref",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// N-CBT画面向け
export const getNCBTScore = async (
  idToken: string | undefined,
  params: any /*NCBTRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<NCBTResponseType>(
      "/v2/ncbt",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// N-CBT画面(データ参照)向け
export const getNCBTScoreDataRef = async (
  idToken: string | undefined,
  params: any /*NCBTRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<NCBTDataRefResponseType>(
      "/v2/ncbt_data_ref",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// SE・TS画面向け
export const getSETSScore = async (
  idToken: string | undefined,
  params: any /*SETSResponseType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<SETSResponseType>(
      "/v2/sets",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

// SE・TS画面(データ参照)向け
export const getSETSScoreDataRef = async (
  idToken: string | undefined,
  params: any /*SETSResponseType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<SETSDataRefResponseType>(
      "/v2/sets_data_ref",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const updateUser = async (
  idToken: string | undefined,
  params: any /*updateUserRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    _sendLogException(idToken, params);
    const httpData = await client.post("/v2/update_user", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const getChatbotMessages = async (
  idToken: string | undefined,
  params: any /*chatbotMessagesRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post<chatbotMessagesResponseType>(
      "/v2/chatbot_messages",
      params,
      httpConfig
    );
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const newChatbotMessage = async (
  idToken: string | undefined,
  params: any /*newChatbotMessageRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post("/v2/new_chatbot_message", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const chatbotLLMMessageStream = async (
  idToken: string | undefined,
  params: chatbotLLMMessageRequestType
) => {
  try {
    const formData = new FormData();
    formData.append("login_user_id", String(params["login_user_id"]));

    const httpConfig = {
      method: "POST",
      body: formData,
      headers: {} as Record<string, string>,
    };
    if (idToken) {
      httpConfig.headers["Authorization"] = `Bearer ${idToken}`;
    }

    // ストリーム取得するために fetch を利用
    const response = await fetch(
      `${process.env.REACT_APP_API_URL ?? "http://localhost:8000"}/v2/chatbot_llm_message_stream`,
      httpConfig
    );
    return response;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};

export const updateChatbotLLMMessage = async (
  idToken: string | undefined,
  params: any /*newChatbotMessageRequestType*/
) => {
  const httpConfig = idToken
    ? { headers: { Authorization: `Bearer ${idToken}` } }
    : {};
  try {
    const httpData = await client.post("/v2/update_chatbot_llm_message", params, httpConfig);
    return httpData.data;
  } catch (e: any) {
    _sendLogException(idToken, params);
    throw e;
  }
};
