import { useAuth0 } from "@auth0/auth0-react";
import App from "../App";
import { loginUser, useAuth0Token } from "./http-requests";
import { loginUserRequestType} from "../common/type";

function LoginProcessing(token:any, user_email:string) {
  const loginInternalProcessing = async (
      email: string, // 検索対象のEメールアドレス
  ) => {
    try {
      const requestParams: loginUserRequestType = {
        email, // 検索対象のEメールアドレス
      };
      await loginUser(token, requestParams);
    } catch (e: any){
      console.log(e);
    }
  };
  loginInternalProcessing(user_email);
}

function Login() {
  const { user, isLoading, error, isAuthenticated, loginWithRedirect, } = useAuth0();
  const { getToken } = useAuth0Token();

  if (error) {
    console.log("Auth0 authentication failed");
    return <div>Oops... {error.message}</div>;
  }
  if (isLoading) {
    return <>Loading...</>;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return <>not authenticated</>;
  }

  if (user === undefined) {
    return <>not authenticated</>;
  }

  LoginProcessing(getToken(), String(user.email))

  return <App />;
}

export default Login;