import React, { FC, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BadgeIcon from '@mui/icons-material/Badge';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Store } from "../store";
import types from "../store/types";

const Sidebar: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(Store);
  const { isAuthenticated, logout } = useAuth0();

  const handleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      closeDrawer();
    };

  const closeDrawer = () => {
    dispatch({ type: types.SIDEBAR_CLICK });
  };

  const handleLogout = async () => {
    try {
      if (isAuthenticated) {
        logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      }
      dispatch({ type: types.SIDEBAR_CLICK });
    } catch (e) {
      alert(e);
    }
  };

  const changeRoute = (url: string) => {
    dispatch({ type: types.SIDEBAR_CLICK });
    navigate(url);
  };

  const menuList = () => (
    <>
      <List>
        <ListItem button key="home" onClick={() => changeRoute("/home")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="ホーム" />
        </ListItem>
        <ListItem button key="account" onClick={() => changeRoute("/account")}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="アカウント" />
        </ListItem>
        <ListItem button key="userInfo" onClick={() => changeRoute(`/userInfo`)}>
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          <ListItemText primary="学籍情報" />
        </ListItem>
        <ListItem button key="userAchievements" onClick={() => changeRoute(`/userAchievements`)}>
          <ListItemIcon>
            <StarBorderIcon />
          </ListItemIcon>
          <ListItemText primary="特記業績" />
        </ListItem>
        <ListItem button key="language" onClick={() => changeRoute("/language")}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary="表示言語" />
        </ListItem>
        <ListItem button key="links" onClick={() => changeRoute("/links")}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="リンク" />
        </ListItem>
        <ListItem button key="point" onClick={() => changeRoute("/point")}>
          <ListItemIcon>
            <CurrencyRubleIcon />
          </ListItemIcon>
          <ListItemText primary="ポイント" />
        </ListItem>
        <ListItem button key="help" onClick={() => changeRoute("/help")}>
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="ヘルプ" />
        </ListItem>
        <ListItem button key="logout" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="ログアウト" />
        </ListItem>
      </List>
    </>
  );

  return (
    <div>
      <Drawer anchor="left" open={state.sidebarState} onClose={handleDrawer()}>
        {menuList()}
      </Drawer>
    </div>
  );
};

export default Sidebar;
