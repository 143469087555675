import React,{ FC, useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styled, { keyframes } from 'styled-components';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { getTrainingIndicator, useAuth0Token } from "../common/http-requests";
import { trainingIndicatorRequestType, trainingIndicatorResponseType } from "../common/type";
import { Store } from "../store";

const BAR_CHART_SIZE: number[] = [
  1000, 320
];

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
  tableHeadStyle : {
    textAlign: "center",
    borderRight: "solid 1px #fff",
    lineHeight: "1rem",
    padding: "10px 0",
  },
  tableCellStyle: {
    padding: "0 16px",
    
  },
  tableCellChartStyle: {
    position: 'relative',
    padding: "0",
    overflow: "hidden",
  },
  accordionDetailsStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // 水平方向の中央揃え
    justifyContent: "center", // 垂直方向の中央揃え
    minHeight: "200px", // 必要に応じて高さを設定
    width: `${BAR_CHART_SIZE[0]}px`, // 修正: 幅を親要素いっぱいに設定
    textAlign: "center", // 修正: テキストを中央揃え
    margin: "0 auto", // 修正: 要素を水平中央に配置
  },
});

// 鳴門教育大学学章のメインカラーである「Utopia Blue」(#226DAE)を基準に指定しました。
const BAR_CHART_COLORS: string[] = [
  "#92DDFE","#529DDE","#226DAE","#023D7E","#a7a7a7",
];

const expand = (width: string) => keyframes`
  from {
    width: 0;
  }
  to {
    width: ${width};
  }
`;

interface BarChartStyleProps {
  width: string;
}

const BarChartStyle = styled.div<BarChartStyleProps>`
  position: relative;
  width: 0;
  height: 20px;
  text-align: center;
  background-color: ${BAR_CHART_COLORS[0]};
  animation: ${props => expand(props.width)} .3s linear forwards;
`;

const BarChartOverStyle = styled.div<BarChartStyleProps>`
  position: relative;
  width: 0;
  height: 20px;
  text-align: left;
  color: #ffffff;
  background-color: ${BAR_CHART_COLORS[1]};
  animation: ${props => expand(props.width)} .3s linear forwards;
  animation-delay: .3s;
`;

const BarChartDataPointStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 5px;
  opacity: 0;
  animation: showElement 0s forwards;
  animation-delay: .8s;

  @keyframes showElement {
  to {
    opacity: 1;
  }
}
`;

const AccordionLabel = styled(Box)(() => ({
  position: "relative",
  width: '100%', 
  height: 'auto', 
  maxWidth: `${BAR_CHART_SIZE[0]}px`, 
  margin: '8px auto 0',
  borderTop: `solid 1px ${BAR_CHART_COLORS[2]}`,
  borderBottom: `solid 1px ${BAR_CHART_COLORS[2]}`,
  color: `${BAR_CHART_COLORS[2]}`,
}));


const TrainingIndicatorPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();
  const [tiMextData, setTIMextData] = useState<any[]>([]);
  const [tiTokushimaData, setTITokushimaData] = useState<any[]>([]);
  const [expandedMext, setExpandedMext] = useState(true);
  const [expandedTokushima, setExpandedTokushima] = useState(true);
  const toggleMext = () => setExpandedMext(!expandedMext);
  const toggleTokushima = () => setExpandedTokushima(!expandedTokushima);
  // const sample = {'training_indicators': [120, 60, 80, 150, 130, 75, 95, 180, 145, 20, 50, 110, 65, 170, 100, 130, 80]};

  const tableHeadList = [
    { label: "区分", width: "8vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "資質・指標", width: "20vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "養成期に求められる能力", color: "black", width: "16vw", backgroundColor: BAR_CHART_COLORS[0] },
    { label: "上回る能力", width: "16vw", color: "white", backgroundColor: BAR_CHART_COLORS[1] },
  ]

  const createMextData = (mextData: trainingIndicatorResponseType) => {
    const threshold = 100;
    const reData: [number, number][] = [];
    for (let i = 0; i < mextData.training_indicators.length; i++) {
      const value = mextData.training_indicators[i];
      if (value > threshold) {
        reData.push([threshold, value - threshold]);
      } else {
        reData.push([value, 0]);
      };
    };
    const fixData = [
      { group: "教職に必要な素養",
        span: 5,
        data: [
          {value: reData[0][0], orverValue: reData[0][1], label: "教職理解等の学び続ける姿勢" },
          {value: reData[1][0], orverValue: reData[1][1], label: "良好な人間関係" },
          {value: reData[2][0], orverValue: reData[2][1], label: "学校組織マメジメント" },
          {value: reData[3][0], orverValue: reData[3][1], label: "連携協働を通じて課題を解決しようとする姿勢" },
          {value: reData[4][0], orverValue: reData[4][1], label: "危機管理の知識や視点" },
        ]},
      { group: "学習指導",
        span: 4,
        data: [
          {value: reData[5][0], orverValue: reData[5][1], label: "学習者中心の授業を創造" },
          {value: reData[6][0], orverValue: reData[6][1], label: "カリキュラム・マメジメント"},
          {value: reData[7][0], orverValue: reData[7][1], label: "授業設計・実践・評価・改善"},
          {value: reData[8][0], orverValue: reData[8][1], label: "各教科等の専門的知識"},
        ]},
      { group: "生徒指導",
        span: 5,
        data: [
          {value: reData[9][0], orverValue: reData[9][1], label: "良さや可能性を伸ばす姿勢"},
          {value: reData[10][0], orverValue: reData[10][1], label: "個に応じた指導や集団指導"},
          {value: reData[11][0], orverValue: reData[11][1], label: "学校生活への適応や人格の成長への援助"},
          {value: reData[12][0], orverValue: reData[12][1], label: "自分らしい生き方を実現するための力を育成"},
          {value: reData[13][0], orverValue: reData[13][1], label: "可能性や活躍の場を引き出す集団づくり"},
        ]},
      { group: "特別支援",
        span: 1,
        data: [
          {value: reData[14][0], orverValue: reData[14][1], label: "学習上・生活上の支援の工夫"},
        ]},
      { group: "ICTや情報・教育データの利活用",
        span: 2,
        data: [
          {value: reData[15][0], orverValue: reData[15][1], label: "情報活用能力を育成するための授業実践"},
          {value: reData[16][0], orverValue: reData[16][1], label: "教育データを適切に活用"},
        ]},
    ];
    setTIMextData(fixData);
  };

  const createTokushimaData = (tokushimaData: trainingIndicatorResponseType) => {
    const threshold = 100;
    const reData: [number, number][] = [];
    for (let i = 0; i < tokushimaData.training_indicators.length; i++) {
      const value = tokushimaData.training_indicators[i];
      if (value > threshold) {
        reData.push([threshold, value - threshold]);
      } else {
        reData.push([value, 0]);
      };
    };
    const fixData = [
      { group: "素養",
        span: 6,
        data: [
          {value: reData[0][0], orverValue: reData[0][1], label: "使命感・倫理観" },
          {value: reData[1][0], orverValue: reData[1][1], label: "識見・学び続ける力" },
          {value: reData[2][0], orverValue: reData[2][1], label: "社会性・コミュニケーション力" },
          {value: reData[3][0], orverValue: reData[3][1], label: "学校組織マネジメント力" },
          {value: reData[4][0], orverValue: reData[4][1], label: "連携・協働力" },
          {value: reData[5][0], orverValue: reData[5][1], label: "危機管理力" },
        ]},
      { group: "授業力・学習指導",
        span: 4,
        data: [
          {value: reData[6][0], orverValue: reData[6][1], label: "カリキュラム・マネジメント力" },
          {value: reData[7][0], orverValue: reData[7][1], label: "授業構想力"},
          {value: reData[8][0], orverValue: reData[8][1], label: "授業実践力"},
          {value: reData[9][0], orverValue: reData[9][1], label: "授業省察力・改善力"},
        ]},
      { group: "担任力・生徒指導",
        span: 4,
        data: [
          {value: reData[10][0], orverValue: reData[10][1], label: "児童生徒理解・指導力"},
          {value: reData[11][0], orverValue: reData[11][1], label: "集団づくり力"},
          {value: reData[12][0], orverValue: reData[12][1], label: "課題解決力"},
          {value: reData[13][0], orverValue: reData[13][1], label: "未来ビジョン育成力"},
        ]},
      { group: "特別な配慮・支援",
        span: 4,
        data: [
          {value: reData[14][0], orverValue: reData[14][1], label: "個に応じた指導・支援力"},
          {value: reData[15][0], orverValue: reData[15][1], label: "チームによる実践"},
          {value: reData[16][0], orverValue: reData[16][1], label: "｢わかった｣｢できた｣を育 む（多様性に基づく）学習支援力"},
          {value: reData[17][0], orverValue: reData[17][1], label: "｢わかった｣｢できた｣を育む（多様性に基づく）生活支援力"},
        ]},
      { group: "ICTの利活用",
        span: 2,
        data: [
          {value: reData[18][0], orverValue: reData[18][1], label: "ＩＣＴを学習指導に利活用する力"},
          {value: reData[19][0], orverValue: reData[19][1], label: "ＩＣＴを効率的な業務の遂行に活用する力"},
        ]},
    ];
    setTITokushimaData(fixData);
  };

  // データ例
  //  {'training_indicators': [523.0, 630.0, ..., 383.0]}
  //const [trainingIndicatorData, setTrainingIndicatorData] = useState<trainingIndicatorResponseType>(DefaultTrainingIndicator);

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getTrainingIndicatorData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getTrainingIndicatorData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      // 全国指標のデータを取得
      const requestMextParams: trainingIndicatorRequestType = {
        login_user_id,
        target_type: "mext",
      };
      const mextData: trainingIndicatorResponseType = await getTrainingIndicator(
        await getToken(),
        requestMextParams
      );
      // console.log("★mextData = ",mextData);

      // 徳島県指標のデータを取得
      const requestTokushimaParams: trainingIndicatorRequestType = {
        login_user_id,
        target_type: "tokushima",
      };
      const tokushimaData: trainingIndicatorResponseType = await getTrainingIndicator(
        await getToken(),
        requestTokushimaParams
      );
      // console.log("★tokushimaData = ",tokushimaData);

      // 全国指標データを整形
      createMextData(mextData);
      // 徳島県指標データを整形
      createTokushimaData(tokushimaData);
      // setTrainingIndicatorData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  interface LinebreaksProps {
    label: string;
  };

  const Linebreaks:React.FC<LinebreaksProps> = ({label}) => {
    const reLabel = label.split("(");
    return (
      <>
        {reLabel[0]}
        {reLabel[1] && (
          <>
            <br />({reLabel[1]}
          </>
        )}
      </>
    );
  };
  
  interface TableCellProps {
    group?: string;
    span?: number;
    data: { label: string; value: number; orverValue: number }[];
  };

  // tableのrowspanの都合上、各項目の最初の１行目はTITableFirstRowを使用
  const TITableFirstRow:React.FC<TableCellProps> = ({group, span, data }) => {
    return (
      <TableRow key={group}>
        <TableCell sx={{ padding: "0 16px"}} rowSpan={span}>{group}</TableCell>
        <TableCell className={classes.tableCellStyle}>{data[0].label}</TableCell>
        <TableCell className={classes.tableCellChartStyle}>
          <BarChartStyle width={`${ (data[0].value/100)*100}%`}>
            <BarChartDataPointStyle>{data[0].value}</BarChartDataPointStyle>
          </BarChartStyle>
        </TableCell>
        <TableCell className={classes.tableCellChartStyle}>
          <BarChartOverStyle  width={`${ (data[0].orverValue/100)*100}%`} >
            <BarChartDataPointStyle>{data[0].orverValue}</BarChartDataPointStyle>
          </BarChartOverStyle>
        </TableCell>
      </TableRow>
    );
  };
  // tableのrowspanの都合上、各項目の２行目からはTITableLeftRowsを使用
  const TITableLeftRows:React.FC<TableCellProps> = ({ data }) => {
    return (
      <>
      {data.map((rowData:any, rowIndex:number) =>(
        rowIndex > 0 && ( // data[0]は重複するのでskip
        <TableRow key={`${rowData.label}-${rowIndex}`}>
            <TableCell className={classes.tableCellStyle}>{rowData.label}</TableCell>
            <TableCell className={classes.tableCellChartStyle}>
              <BarChartStyle width={`${ (rowData.value/100)*100}%`}>
                <BarChartDataPointStyle>{rowData.value}</BarChartDataPointStyle>
              </BarChartStyle>
            </TableCell>
            <TableCell className={classes.tableCellChartStyle}>
              <BarChartOverStyle  width={`${ (rowData.orverValue/100)*100}%`}>
                <BarChartDataPointStyle>{rowData.orverValue}</BarChartDataPointStyle>
              </BarChartOverStyle>
            </TableCell>
        </TableRow>
      )))}
      </>
    );
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {feedbackInfo.open &&
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Paper className={classes.chartContainer}>
              <AccordionLabel>
                <AccordionSummary
                  onClick={toggleMext}
                  expandIcon={expandedMext ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  <Typography>教員資質指標（文部科学省）</Typography>
                </AccordionSummary>
              </AccordionLabel>
              {expandedMext && (
                <AccordionDetails className={classes.accordionDetailsStyle}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead key="table-head">
                        <TableRow key="table-row">
                          {tableHeadList.map((item, index) => (
                            <TableCell 
                              key={`${item.label}-${index}`}
                              className={classes.tableHeadStyle} 
                              sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                            ><Linebreaks label={item.label} />
                            </TableCell>
                          ))}
                        </TableRow>
                        <tr><th colSpan={4} style={{ height:"10px" }}></th></tr>
                      </TableHead>
                      <TableBody key="table-body">
                        {tiMextData && tiMextData.map((item:any, index:number) =>(
                          <React.Fragment key={`${item.group}-${index}`}>
                            <TITableFirstRow key={`${item.group}-${index}`} group={item.group} span={item.span} data={item.data} />
                            <TITableLeftRows key={`${item.data}-${index}`} data={item.data} />
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              )}
              <AccordionLabel>
                <AccordionSummary
                  onClick={toggleTokushima}
                  expandIcon={expandedTokushima ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  <Typography>とくしま教員育成指標</Typography>
                </AccordionSummary>
              </AccordionLabel>
              {expandedTokushima && (
                <AccordionDetails className={classes.accordionDetailsStyle}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead key="table-head">
                        <TableRow key="table-row">
                          {tableHeadList.map((item, index) => (
                            <TableCell 
                              key={`${item.label}-${index}`}
                              className={classes.tableHeadStyle} 
                              sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                            ><Linebreaks label={item.label} />
                            </TableCell>
                          ))}
                        </TableRow>
                        <tr><th colSpan={4} style={{ height:"10px" }}></th></tr>
                      </TableHead>
                      <TableBody key="table-body">
                        {tiTokushimaData && tiTokushimaData.map((item:any, index:number) =>(
                          <React.Fragment key={`${item.group}-${index}`}>
                            <TITableFirstRow key={`${item.group}-${index}`} group={item.group} span={item.span} data={item.data} />
                            <TITableLeftRows key={`${item.data}-${index}`} data={item.data} />
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              )}
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default TrainingIndicatorPage;
