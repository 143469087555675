import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
} from "@mui/material";
// import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { useAuth0Token, getUserAchievements  } from "../common/http-requests";
import { userAchievementsRequestType, /* userAchievementsResponseType */ } from "../common/type";
import { Store } from "../store";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { Toolbar } from "../components/FooterToolbar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";

const UserAchievementsPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  // const classes = useStyles();
  const { isDesktop, isMobile } = useCustomMediaQuery();

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getUserAchievementsData(Number(state.userId));
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getUserAchievementsData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: userAchievementsRequestType = {
        login_user_id,
      };
      const data /*: userAchievementsResponseType */ = await getUserAchievements(
        await getToken(),
        requestParams
      );

      // data例
      // console.log("=====");
      // console.log(JSON.stringify(data));
      // [
      //   {"id":2,"user_id":411,"achievement_type":1,"achievement_value":"aaa1"},
      //   {"id":3,"user_id":411,"achievement_type":2,"achievement_value":"bbb1"},
      //   {"id":4,"user_id":411,"achievement_type":2,"achievement_value":"bbb2"}
      // ]

      // achievement_type
      //  1:研究テーマ, 2:受賞歴, 3:語学, 4:資格, 5:芸術・文化関係, 6:体育関係, 7:課外活動の発展, 8:その他
      // achievement_value
      //  最大100文字　※frontend/src/pages/ReportDetailPage.tsx 等を参考に入力文字数制御

    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
            {/* 実装をお願いします。 */}
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default UserAchievementsPage;
