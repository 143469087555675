import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
} from "@mui/material";
// import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { useAuth0Token, getHelp  } from "../common/http-requests";
import { helpRequestType, /* helpResponseType */ } from "../common/type";
import { Store } from "../store";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { Toolbar } from "../components/FooterToolbar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";

const HelpPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  // const classes = useStyles();
  const { isDesktop, isMobile } = useCustomMediaQuery();

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getHelpData();
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getHelpData = async () => {
    setIsSending(true);
    try {
      // TODO: 表示言語画面の設定内容を反映 "ja" or "en"
      const requestParams: helpRequestType = {
        language: "ja"
      };
      const data /*: helpResponseType */ = await getHelp(
        await getToken(),
        requestParams
      );

      // data例
      // console.log("=====");
      // console.log(JSON.stringify(data));
      // [
      //   {"category_name":"システム全般",
      //     "help_contents":[
      //       {"question_text":"質問文1","answer_text":"回答文1"},
      //       {"question_text":"質問文2","answer_text":"回答文2"},
      //       ...
      //     ]
      //   },
      //   {"category_name":"DS",
      //     "help_contents":[
      //       {"question_text":"質問文11","answer_text":"回答文11"},
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
            {/* 実装をお願いします。 */}
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default HelpPage;
