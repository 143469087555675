import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
} from "@mui/material";
// import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { useAuth0Token, getUserInfo  } from "../common/http-requests";
import { userInfoRequestType, userInfoResponseType } from "../common/type";
import { Store } from "../store";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { Toolbar } from "../components/FooterToolbar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";

const UserInfoPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  // const classes = useStyles();
  const { isDesktop, isMobile } = useCustomMediaQuery();

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getUserInfoData(Number(state.userId));
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getUserInfoData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: userInfoRequestType = {
        login_user_id,
      };
      const data: userInfoResponseType = await getUserInfo(
        await getToken(),
        requestParams
      );

      // data例
      // {"student_number":"19721014",
      //   "user_name":"鳴門 19721014",
      //   "department_name":"学校教育学部",
      //   "major_name":"学校教育教員養成課程",
      //   "course_name":"幼児教育専修",
      //   "academic_degree_name":"学士（教育学）",
      //   "license_names":["幼稚園教諭 １種","小学校教諭 １種"]}
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
            {/* 実装をお願いします。 */}
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default UserInfoPage;
